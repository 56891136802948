<template>
  <div>
    <v-card class="mt-9">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h5 class="font-weight-light">BILLING REPORT</h5>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md4 class="mx-2">
          <v-select
            v-model="month_of"
            class="mx-3 pt-5"
            :items="year_items"
            item-value="year"
            item-text="year"
            label="Year Of"
            required
            @change="selected_year"
            :rules="rules.combobox_rule"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-data-table dense
                    :headers="headers"
                    :items="data_items"
                    :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Data</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearch"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.id }}
            </td>
            <td>
              {{ item!=null?item.company_name:'' }}
            </td>
            <td>
              {{ formatPrice(item.member_payment[0].amount) }}
            </td>
            <td>
              {{ item.member_payment[0].year_of }}
            </td>
            <td>
              <v-chip color="error" v-if="item.member_payment[0].deposit_slip_id===null">
                UNPAID
              </v-chip>
              <v-chip color="success" v-else>
                PAID
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose
    , mdiAccountOff
    , mdiAccountCheck
    , mdiPlus
    , mdiEyeCircle
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      month_of: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      is_view_rooms: false,
      selected_branch: {},
      year_items: [],
      data_items: [],
      search: '',
      headers: [
        {text: 'ID', value: 'id', sortable: true},
        {text: 'Name', value: 'company_name', sortable: true},
        {text: 'Amount', value: 'due_date', sortable: true},
        {text: 'Year Of', value: 'or_date', sortable: true},
        {text: 'Status', value: 'status', sortable: true},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiAccountOff
          , mdiAccountCheck
          , mdiPlus
          , mdiEyeCircle
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_month', ['get_transaction_month']),
      ...mapActions('members_payments', ['list_of_billing']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      initialize_data() {
        this.get_transaction_month()
          .then(response => {
            this.year_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_year() {
        const data = new FormData()
        data.append('year_of', this.month_of);
        this.list_of_billing(data)
          .then(response => {
            this.data_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
